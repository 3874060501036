import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RecentMetricsHoC } from 'components/sections/machine-calibration/recent-metrics';
import { CollectDataHoC } from 'components/sections/machine-calibration/steps/collect-data';
import { ReviewMetricHoC } from 'components/sections/machine-calibration/steps/review-metric';
import { SetupHoc } from 'components/sections/machine-calibration/steps/setup';
import { AimingProvider } from 'contexts/aiming.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineCalibrationContext } from 'contexts/machine-calibration.context';
import { MatchingShotsProvider } from 'contexts/pitch-lists/matching-shots.context';
import { CalibrationStep } from 'enums/machine.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const MachineCalibrationRouter = () => {
  const { machineCalibration } = useContext(CookiesContext);
  const { metric, pitches, step, setStep } = useContext(
    MachineCalibrationContext
  );

  return (
    <ErrorBoundary componentName="MachineCalibrationRouter">
      {step === CalibrationStep.Setup && (
        <Card>
          <SetupHoc />
        </Card>
      )}

      {step === CalibrationStep.Setup && <RecentMetricsHoC />}

      {step === CalibrationStep.CollectData && pitches.length > 0 && (
        <Card>
          <MatchingShotsProvider>
            <AimingProvider
              newerThan={
                machineCalibration.start_date ?? new Date().toISOString()
              }
            >
              <CollectDataHoC />
            </AimingProvider>
          </MatchingShotsProvider>
        </Card>
      )}

      {step === CalibrationStep.ReviewMetric && (
        <Card>{metric ? <ReviewMetricHoC /> : <Spinner size="3" />}</Card>
      )}

      {step === CalibrationStep.TrainError && (
        <Card>
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Heading size={RADIX.HEADING.SIZE.SM} className="text-titlecase">
              Training Error
            </Heading>

            <Box>
              <Text>
                There was an error while calibrating your machine but your
                calibration session is saved.
              </Text>
            </Box>

            <Box>
              <Text>Please contact support for assistance.</Text>
            </Box>

            <Flex gap={RADIX.FLEX.GAP.SM}>
              <Button onClick={() => setStep(CalibrationStep.Setup)}>
                {t('common.back')}
              </Button>
              <ContactSupportButton />
            </Flex>
          </Flex>
        </Card>
      )}
    </ErrorBoundary>
  );
};
